import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Footer from './Footer'
import Modal from './Modal'
import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import Parsley from 'parsleyjs'
import $ from 'jquery'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { type: this.props.match.params.type || "b2b", pid: "A"+this.makeid(19), name: "", lastname: "", email: "", phone: "", pass: "", code: "", checkbox1: false, checkbox2: false, checkbox3: false, checkbox4: false }
    this._bind('handleInputChange', 'onEnterPressed', 'validateForm', 'sendForm', 'sendCode')

    Parsley.addMessages('pl', {
      defaultMessage: "Wartość wygląda na nieprawidłową",
      type: {
        email:        "Wpisz poprawny adres e-mail.",
        url:          "Wpisz poprawny adres URL.",
        number:       "Wpisz poprawną liczbę.",
        integer:      "Dozwolone są jedynie liczby całkowite.",
        digits:       "Dozwolone są jedynie cyfry.",
        alphanum:     "Dozwolone są jedynie znaki alfanumeryczne."
      },
      notblank:       "Pole nie może być puste.",
      required:       "Pole jest wymagane.",
      pattern:        "Pole zawiera nieprawidłową wartość.",
      min:            "Wartość nie może być mniejsza od %s.",
      max:            "Wartość nie może być większa od %s.",
      range:          "Wartość powinna zaweriać się pomiędzy %s a %s.",
      minlength:      "Minimalna ilość znaków wynosi %s.",
      maxlength:      "Maksymalna ilość znaków wynosi %s.",
      length:         "Ilość znaków wynosi od %s do %s.",
      mincheck:       "Wybierz minimalnie %s opcji.",
      maxcheck:       "Wybierz maksymalnie %s opcji.",
      check:          "Wybierz od %s do %s opcji.",
      equalto:        "Wartości nie są identyczne."
    });

    if (this.props.lang === "pl") {
      Parsley.setLocale('pl');
    }
  }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  componentDidMount() {
  	document.addEventListener("keyup", this.onEnterPressed)
    setTimeout(() => { this.setState({ anim: true }) }, 600)
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onEnterPressed)
  }

  onEnterPressed(event) {
    event.preventDefault()
    if (event.keyCode === 13) {
      document.getElementById("button").click()
    }
  }

  handleInputChange(event) {
  	const target = event.target
  	let value = target.type === 'checkbox' ? target.checked : target.value
  	let name = target.name

    if (event.target.pattern) {
      let pattern = new RegExp(event.target.pattern)
      value = pattern.test(event.target.value) ? event.target.value : this.state[name] || "";
    }

    if( event.target.className === "input-error" ) event.target.className = ""

  	this.setState({
  	  [name]: value
  	})
  }

  validateForm(e) {
    e.preventDefault()

    if($('.form').parsley().validate()) {
      this.sendForm()
    }
  }

  sendForm() {
    let data  = {
      pid: this.state.pid,
      firstname: this.state.name,
      lastname: this.state.lastname,
      phone: this.state.phone,
      email: this.state.email,
      password: this.state.pass,
      marketingAgreement: this.state.checkbox2,
      dataProcessingAgreement: this.state.checkbox3,
      partnerMarketingAgreement: this.state.checkbox4,
      language: this.props.lang.toUpperCase()
    }

    fetch(this._apiBase + "register/b2c", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'x-api-key': '46B9D48A125733B4C9226CE570007'
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
      return response.json()
    })
    .then((result) => {      
      if (result.answer === "OK") {
        //window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : ""}}), true)
        this.result = result.result
        this.setState({ codePopup: true })
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }        
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Wystąpił błąd."}}), true)        
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Wystąpił błąd." }}), true)      
    })
  }

  sendCode(e) {
    e.preventDefault()

    if($('.code-form').parsley().validate()) {
      let data  = {
        pid: this.state.pid,
        phone: this.state.phone,
        email: this.state.email,
        code: this.state.code
      }

      fetch(this._apiBase + "register/b2c/verify", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Accept-Charset': 'utf-8',
          'Accept-Language': this.props.lang,
          'x-api-key': '46B9D48A125733B4C9226CE570007'
        },
        body: JSON.stringify(data)
      })
      .then((response) => {
        return response.json()
      })
      .then((result) => {      
        if (result.answer === "OK") {
          //window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : ""}}), true)
          this.result = result.result
          this.setState({ codePopup: false })
        } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var i = 0; i < result.result.errors.length; i++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
          }        
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Wystąpił błąd."}}), true)        
        }
      }, (error) => {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Wystąpił błąd." }}), true)      
      })
    }
  }

  render() {
    const page = this.props.data.start

    let type = ""

    switch(this.state.type) {
      case "b2c":
        type = (
          <div className={ this.state.anim ? "start-box show" : "start-box" }>
            <div className="inputs">
              <div className="have-account"><p>{ page.haveAccount } <a href={ this.props.lang === "pl" ? "https://panelbiznesowy.itaxi.pl" : "https://panelbiznesowy.itaxi.pl/en" }>{ page.logIn }</a></p></div>
              <h3>{ page.title }</h3>
              <div className="switch-type">
                <div className="icon"></div>
                <div className="switch-types">
                  <NavLink to="/">{ page.b2c }</NavLink>
                  <NavLink to="/b2b"><div className={ this.state.type === "b2b" ? "switch b2b" : "switch b2c" }></div></NavLink>
                  <NavLink to="/b2b">{ page.b2b }</NavLink>
                </div>
              </div>
              <h4>{ page.b2cData.registerWith }</h4>
              <div className="social-buttons">
                <GoogleLogin
                  clientId="138390310003-947eslrcgbt2kiobqonhjgn8hrul01hp.apps.googleusercontent.com"
                  buttonText={ page.b2cData.googleAccount }
                  onSuccess={(data) => {
                    this.setState({ name: data.profileObj.givenName, lastname: data.profileObj.familyName, email: data.profileObj.email })
                  }}
                  onFailure={(err) => { console.log(err) }}
                  cookiePolicy={'single_host_origin'}
                />
                <FacebookLogin
                  appId="534480547100064"
                  autoLoad={false}
                  fields="first_name,last_name,name,email"
                  callback={ (data) => {
                    this.setState({ name: data.first_name, lastname: data.last_name, email: data.email })
                  }} 
                  render={renderProps => (
                    <button className="fb-btn" onClick={renderProps.onClick}>{ page.b2cData.facebookAccount }</button>
                  )}
                />
              </div>
              <h4>{ page.b2cData.orFill }</h4>
              <form className="form">
                <div className="input">
                  <label htmlFor="name"><span>* </span>{ page.b2cData.name }</label>
                  <input type="text" name="name" id="name" className={ this.state.nameError ? "input-error" : "" } value={ this.state.name } onChange={ this.handleInputChange } required/>
                  { this.state.nameError ? <p className="error">{ "name error" }</p> : null }
                </div>
                <div className="input">
                  <label htmlFor="lastname"><span>* </span>{ page.b2cData.lastname }</label>
                  <input type="text" name="lastname" id="lastname" className={ this.state.lastnameError ? "input-error" : "" } value={ this.state.lastname } onChange={ this.handleInputChange } required/>
                  { this.state.lastnameError ? <p className="error">{ "lastname error" }</p> : null }
                </div>
                <div className="input">
                  <label htmlFor="email"><span>* </span>{ page.b2cData.email }</label>
                  <input type="email" name="email" id="email" className={ this.state.emailError ? "input-error" : "" } value={ this.state.email } onChange={ this.handleInputChange } required/>
                  { this.state.emailError ? <p className="error">{ "email error" }</p> : null }
                </div>
                <div className="input">
                  <label htmlFor="phone"><span>* </span>{ page.b2cData.phone }</label>
                  <input type="phone" pattern="^[0-9+-]*$" name="phone" id="phone" className={ this.state.phoneError ? "input-error" : "" } value={ this.state.phone } onChange={ this.handleInputChange } required/>
                  { this.state.phoneError ? <p className="error">{ "phone error" }</p> : null }
                </div>
                <div className="input">
                  <label htmlFor="pass"><span>* </span>{ page.b2cData.pass }</label>
                  <input type="password" name="pass" id="pass" className={ this.state.passError ? "input-error" : "" } value={ this.state.pass } onChange={ this.handleInputChange } required/>
                  { this.state.passError ? <p className="error">{ "pass error" }</p> : null }
                </div>
                <div className="checkboxes">
                  <div className="input checkbox">
                    <input type="checkbox" name="checkbox1" id="all" onChange={ () => { if (this.state.checkbox1) { this.setState({ checkbox1: false, checkbox2: false, checkbox3: false, checkbox4: false }) } else { this.setState({ checkbox1: true, checkbox2: true, checkbox3: true, checkbox4: true }) } } } checked={ this.state.checkbox1 ? true : false }/>
                    <label htmlFor="all">{ page.all }</label>
                    { this.state.checkbox1Error && !this.state.checkbox1 ? <p className="checkbox-error">{ page.checkboxRequired }</p> : null }
                  </div>
                  <div className="input checkbox">
                    <input type="checkbox" name="checkbox2" id="checkbox2" onChange={ () => { if (this.state.checkbox2) { this.setState({ checkbox1: false, checkbox2: false }) } else { this.setState({ checkbox2: true }) } } } checked={ this.state.checkbox2 ? true : false } required/>
                    <label htmlFor="checkbox2">{ page.b2cData.checkbox1 }</label>
                    { this.state.checkbox2Error && !this.state.checkbox2 ? <p className="checkbox-error">{ page.checkboxRequired }</p> : null }
                  </div>
                  <div className="input checkbox">
                    <input type="checkbox" name="checkbox3" id="checkbox3" onChange={ () => { if (this.state.checkbox3) { this.setState({ checkbox1: false, checkbox3: false }) } else { this.setState({ checkbox3: true }) } } } checked={ this.state.checkbox3 ? true : false }/>
                    <label htmlFor="checkbox3">{ page.b2cData.checkbox2 }</label>
                    { this.state.checkbox3Error && !this.state.checkbox3 ? <p className="checkbox-error">{ page.checkboxRequired }</p> : null }
                  </div>
                  <div className="input checkbox">
                    <input type="checkbox" name="checkbox4" id="checkbox4" onChange={ () => { if (this.state.checkbox4) { this.setState({ checkbox1: false, checkbox4: false }) } else { this.setState({ checkbox4: true }) } } } checked={ this.state.checkbox4 ? true : false }/>
                    <label htmlFor="checkbox4">{ page.b2cData.checkbox3 }</label>
                    { this.state.checkbox4Error && !this.state.checkbox4 ? <p className="checkbox-error">{ page.checkboxRequired }</p> : null }
                  </div>
                </div>
                <div className="btn-holder">
                  <button className="btn yellow" id="button" onClick={ this.validateForm }><span>{ page.registerBtn }</span></button>
                </div>
              </form>
            </div>
          </div>
        )
      break
      case "b2b":
        type = (
          <div className={ this.state.anim ? "start-box show" : "start-box" }>
            <div className="inputs">
            <div className="have-account"><p>{ page.haveAccount } <a href={ this.props.lang === "pl" ? "https://panelbiznesowy.itaxi.pl" : "https://panelbiznesowy.itaxi.pl/en" }>{ page.logIn }</a></p></div>
              <h3>{ page.title }</h3>
              <div className="switch-type">
                <div className="icon">
                  <img src="/assets/img/ic_menu_b2b.svg" alt=""/>
                </div>
                { /*<div className="switch-types">
                  <NavLink to="/">{ page.b2c }</NavLink>
                  <NavLink to="/"><div className={ this.state.type === "b2b" ? "switch b2b" : "switch b2c" }></div></NavLink>
                  <NavLink to="/b2b">{ page.b2b }</NavLink>
                </div> */ }
              </div>
              <div className="input">
                <div className="btn-holder" style={{ textAlign: "center" }}>
                  <NavLink to="/b2b/single" className="btn yellow small" onClick={ () => { window.gtag('event', 'b2b_rejestruje_1osdzialalnosc') } }><span>{ page.b2bData.single }</span></NavLink>
                </div>
                <h4>{ page.b2bData.howItWorks }</h4>
                <ul>
                  { page.b2bData.howItWorksSingle.map((item, i) => {
                    return (
                      <li key={ i }>{ item }</li>
                    )
                  }) }
                </ul>
              </div>
              <div className="input">
                <div className="sep"></div>
              </div>
              <div className="input">
                <div className="btn-holder" style={{ textAlign: "center" }}>
                  <NavLink to="/b2b/company" className="btn yellow small" onClick={ () => { window.gtag('event', 'b2b_rejestruje_firma') } }><span>{ page.b2bData.company }</span></NavLink>
                </div>
                <h4>{ page.b2bData.howItWorks }</h4>
                <ul>
                  { page.b2bData.howItWorksCompany.map((item, i) => {
                    return (
                      <li key={ i }>{ item }</li>
                    )
                  }) }
                </ul>
              </div>
              <p dangerouslySetInnerHTML={{ __html: page.b2bData.moreInfo }} className="access"></p>
            </div>
          </div>
        )
      break
      default:
        type = null
      break
    }

    let modal = (
      <div className="password-modal">
        <div className="inputs">
          <h2>{ page.b2cData.itTitle }</h2>
          <h4>{ page.b2cData.phoneVerification }</h4>
          <form className="code-form">
            <div className="input">
              <label htmlFor="name">{ page.b2cData.codeVerification }</label>
              <input type="text" name="code" id="code" value={ this.state.code } onChange={ this.handleInputChange } required/>
            </div>
          </form>
        </div>
        <div className="buttons">
          <button className="btn yellow" id="button" onClick={ this.sendCode }><span>{ page.finish }</span></button>
        </div>
      </div>
    )

    return (
      <main>
        <section className={ this.state.type === "b2b" ? "start b2b" : "start b2c" }>
          { type }
        </section>
        <Footer data={ this.props.data }/>
        <Modal close="hide" open={ this.state.codePopup } hidePopup={ () => { this.setState({ codePopup: false }) } }>{ modal }</Modal>
      </main>
    )
  }
}
