import 'react-app-polyfill/ie9'
import 'custom-event-polyfill'
import 'fetch-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import MainView from './app/MainView'
import "./scss/style.scss"

export default class Main {

	init() {
		this.confirm = false
		this.id = 0
		this.app = false

		if (true) {
			this.preloadJSON("/data/en/translations.json")
			this.lang = "en"
		} else {
			this.preloadJSON("/data/pl/translations.json")
			this.lang = "pl"
		}

		let params = this.getURLParams()

		if (params && params.pid) {
			sessionStorage.setItem("pid", params.pid)
		}

		if (params && params.hms) {
			sessionStorage.setItem("hms", params.hms)
		}

		if ((params && params.pid) || sessionStorage.getItem("app")) {
			sessionStorage.setItem("app", true)
			this.app = true
		}

		if (params && params.id) {
			this.confirm = true
			this.id = params.id

			let sessionLang = sessionStorage.getItem("lang")

			if (sessionLang && sessionLang != this.lang) {
				window.location.href = window.location.href + "#en"
			}
		} else {
			sessionStorage.setItem("lang", this.lang)
		}
	}

	getURLParams() {
		let search = window.location.search || ""
		search = search.replace("?", "")
		let params = search.split("&")
		let obj = {}
		for (var i = 0; i < params.length; i++) {
			params[i] = params[i].split("=")
			obj[params[i][0]] = params[i][1]
		}
		return obj
	}

	preloadJSON( dataURL) {
		fetch(dataURL)
		.then((response) => {
		    return response.json()
		})
		.then((result) => {
		    this.data = result
		    this.ready()
		})
	}

	ready() {
		this.render()
	}

	render() {
		const mainContainer = document.getElementById('app')

		ReactDOM.render(
			<Router>
				<MainView 
					lang={ this.lang }
					data={ this.data }
					confirm={ this.confirm }
					id={ this.id }
					app={ this.app } />
			</Router>
		, mainContainer)
	}
}

const app = new Main()
app.init()