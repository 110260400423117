import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Footer from './Footer'
import Modal from './Modal'
import Parsley from 'parsleyjs'
import $ from 'jquery'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { name: "", lastname: "", email: "", phone: "", company: "", checkbox1: false, checkbox2: false, checkbox3: false, popup: false }
    this._bind('handleInputChange', 'onEnterPressed', 'validateForm', 'sendForm', 'goTo', 'validate')

    Parsley.addMessages('pl', {
      defaultMessage: "Wartość wygląda na nieprawidłową",
      type: {
        email:        "Wpisz poprawny adres e-mail.",
        url:          "Wpisz poprawny adres URL.",
        number:       "Wpisz poprawną liczbę.",
        integer:      "Dozwolone są jedynie liczby całkowite.",
        digits:       "Dozwolone są jedynie cyfry.",
        alphanum:     "Dozwolone są jedynie znaki alfanumeryczne."
      },
      notblank:       "Pole nie może być puste.",
      required:       "Pole jest wymagane.",
      pattern:        "Pole zawiera nieprawidłową wartość.",
      min:            "Wartość nie może być mniejsza od %s.",
      max:            "Wartość nie może być większa od %s.",
      range:          "Wartość powinna zaweriać się pomiędzy %s a %s.",
      minlength:      "Minimalna ilość znaków wynosi %s.",
      maxlength:      "Maksymalna ilość znaków wynosi %s.",
      length:         "Ilość znaków wynosi od %s do %s.",
      mincheck:       "Wybierz minimalnie %s opcji.",
      maxcheck:       "Wybierz maksymalnie %s opcji.",
      check:          "Wybierz od %s do %s opcji.",
      equalto:        "Wartości nie są identyczne."
    });

    if (this.props.lang === "pl") {
      Parsley.setLocale('pl');
    }
  }

  componentDidMount() {
  	document.addEventListener("keyup", this.onEnterPressed)
    setTimeout(() => { this.setState({ anim: true }) }, 600)
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onEnterPressed)
  }

  onEnterPressed(event) {
    event.preventDefault()
    if (event.keyCode === 13) {
      document.getElementById("button").click()
    }
  }

  handleInputChange(event) {
  	const target = event.target
  	let value = target.type === 'checkbox' ? target.checked : target.value
  	let name = target.name

    if (event.target.pattern) {
      let pattern = new RegExp(event.target.pattern)
      value = pattern.test(event.target.value) ? event.target.value : this.state[name] || "";
    }

    if( event.target.className === "input-error" ) event.target.className = ""

  	this.setState({
  	  [name]: value
  	})
  }

  validateForm(e) {
    e.preventDefault()

    if($('.form').parsley().validate()) {
      this.sendForm()
    }
  }

  sendForm() {
    let data  = {
      name: this.state.company,
      firstname: this.state.name,
      lastname: this.state.lastname,
      phone: this.state.phone,
      email: this.state.email,
      marketingAgreement: this.state.checkbox2,
      partnerMarketingAgreement: this.state.checkbox3,
      dataProcessingAgreement: true,
      language: this.props.lang.toUpperCase()
    }

    fetch(this._apiBase + "register/b2b", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'x-api-key': '46B9D48A125733B4C9226CE570007'
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
      return response.json()
    })
    .then((result) => {      
      if (result.answer === "OK") {
        //window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : ""}}), true)
        this.result = result.result
        this.setState({ popup: true })
        window.gtag('event', 'b2b_rejestruje_firma_wysylaformularz')
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }        
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Wystąpił błąd."}}), true)        
      }
      if (result.fieldData && result.fieldData.fields.length > 0) {
        this.validate(result.fieldData.fields)
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Wystąpił błąd." }}), true)      
    })
  }

  goTo() {
    window.gtag('event', 'b2b_rejestruje_firma_formularzwyslany_ok')
    if (this.props.app) {
      let data = {
        email: this.state.email,
        business: true,
        hms: sessionStorage.getItem("hms") && sessionStorage.getItem("hms") === "true" ? true : false
      }

      fetch(this._apiBase + "register/link", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Accept-Charset': 'utf-8',
          'Accept-Language': this.props.lang,
          'x-api-key': '46B9D48A125733B4C9226CE570007'
        },
        body: JSON.stringify(data)
      })
      .then((response) => {
        return response.json()
      })
      .then((result) => {      
        if (result.answer === "OK") {
          window.location.href = result.result.value
        } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var i = 0; i < result.result.errors.length; i++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
          }        
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Wystąpił błąd."}}), true)        
        }
      }, (error) => {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Wystąpił błąd." }}), true)      
      })
    } else {
      window.location.href = this.props.lang === "pl" ? "https://panelbiznesowy.itaxi.pl" : "https://panelbiznesowy.itaxi.pl/en"
    }
  }

  validate(fields) {
    document.querySelectorAll("input.input-error").forEach((item) => {
      item.className = ""
    })
    for (var i = 0; i < fields.length; i++) {
      let field = fields[i]
      let sfield = field.split(".")
      if (sfield.length > 1) {
        field = sfield[1]
      } else {
        field = sfield[0]
      }
      if(document.getElementById(field)) document.getElementById(field).className = "input-error"
      if(field === "personName") { if(document.getElementById("businessUserId")) document.getElementById("businessUserId").className = "input-error" }
    }

    if (fields.length > 0) {
      let first = document.querySelectorAll("input.input-error")
      if(first[0]) first[0].focus()
    }
  }

  render() {
    const page = this.props.data.start.b2bData

    const modal = (
      <div className="password-modal">
        <div className="inputs">
          <h2>{ page.success }</h2>
          <div className="input">
            <h4 dangerouslySetInnerHTML={{ __html: page.successDescription }}></h4>
          </div>
        </div>
        <div className="buttons">
          <button className="btn yellow" id="button" onClick={ this.goTo }><span>{ page.ok }</span></button>
        </div>
      </div>
    )

    return (
      <main>
        <section className="start b2b">
          <div className={ this.state.anim ? "start-box show" : "start-box" }>
            <NavLink to="/b2b" className="close"></NavLink>
            <form className="form">
              <div className="inputs">              
                <h3>{ page.registerCompany }</h3>
                <h4 className="input">{ page.fillForm }</h4>
                <div className="input">
                  <label htmlFor="name"><span>* </span>{ page.name }</label>
                  <input type="text" name="name" id="name" className={ this.state.nameError ? "input-error" : "" } value={ this.state.name } onChange={ this.handleInputChange } required/>
                  { this.state.nameError ? <p className="error">{ "name error" }</p> : null }
                </div>
                <div className="input">
                  <label htmlFor="lastname"><span>* </span>{ page.lastname }</label>
                  <input type="text" name="lastname" id="lastname" className={ this.state.lastnameError ? "input-error" : "" } value={ this.state.lastname } onChange={ this.handleInputChange } required/>
                  { this.state.lastnameError ? <p className="error">{ "lastname error" }</p> : null }
                </div>
                <div className="input">
                  <label htmlFor="company"><span>* </span>{ page.companyName }</label>
                  <input type="text" name="company" id="company" className={ this.state.companyError ? "input-error" : "" } value={ this.state.company } onChange={ this.handleInputChange } required/>
                  { this.state.companyError ? <p className="error">{ "company error" }</p> : null }
                </div>
                <div className="input">
                  <label htmlFor="email"><span>* </span>{ page.email }</label>
                  <input type="email" name="email" id="email" className={ this.state.emailError ? "input-error" : "" } value={ this.state.email } onChange={ this.handleInputChange } required/>
                  { this.state.emailError ? <p className="error">{ "email error" }</p> : null }
                </div>
                <div className="input">
                  <label htmlFor="phone"><span>* </span>{ page.phone }</label>
                  <input type="phone" pattern="^[0-9+-]*$" name="phone" id="phone" className={ this.state.phoneError ? "input-error" : "" } value={ this.state.phone } onChange={ this.handleInputChange } required/>
                  { this.state.phoneError ? <p className="error">{ "phone error" }</p> : null }
                </div>
                <div className="checkboxes">
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: page.rodoDisclaimerCompany }}></p>
                  </div>
                  <div className="input checkbox">
                    <input type="checkbox" name="checkbox1" id="all" onChange={ () => { if (this.state.checkbox1) { this.setState({ checkbox1: false, checkbox2: false, checkbox3: false, checkbox4: false }) } else { this.setState({ checkbox1: true, checkbox2: true, checkbox3: true, checkbox4: true }) } } } checked={ this.state.checkbox1 ? true : false }/>
                    <label htmlFor="all">{ page.all }</label>
                    { this.state.checkbox1Error && !this.state.checkbox1 ? <p className="checkbox-error">{ page.checkboxRequired }</p> : null }
                  </div>
                  <div className="input checkbox">
                    <input type="checkbox" name="checkbox2" id="checkbox2" onChange={ () => { if (this.state.checkbox2) { this.setState({ checkbox1: false, checkbox2: false }) } else { this.setState({ checkbox2: true }) } } } checked={ this.state.checkbox2 ? true : false }/>
                    <label htmlFor="checkbox2">{ page.checkbox1Company }</label>
                    { this.state.checkbox2Error && !this.state.checkbox2 ? <p className="checkbox-error">{ page.checkboxRequired }</p> : null }
                  </div>
                  <div className="input checkbox">
                    <input type="checkbox" name="checkbox3" id="checkbox3" onChange={ () => { if (this.state.checkbox3) { this.setState({ checkbox1: false, checkbox3: false }) } else { this.setState({ checkbox3: true }) } } } checked={ this.state.checkbox3 ? true : false }/>
                    <label htmlFor="checkbox3">{ page.checkbox2Company }</label>
                    { this.state.checkbox3Error && !this.state.checkbox3 ? <p className="checkbox-error">{ page.checkboxRequired }</p> : null }
                  </div>
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: page.iod }}></p>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: page.accept }} className="terms"></p>
                </div>
                <div className="btn-holder">
                  <button className="btn yellow" id="button" onClick={ this.validateForm }><span>{ page.registerBtn }</span></button>
                </div>
              </div>
            </form>
          </div>
        </section>
        <Footer data={ this.props.data }/>
        <Modal close="hide" open={ this.state.popup } hidePopup={ () => { this.setState({ popup: false }) } }>{ modal }</Modal>
      </main>
    )
  }
}
