import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Footer from './Footer'
import Modal from './Modal'
import Parsley from 'parsleyjs'
import $ from 'jquery'

export default class extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { pid: "A"+this.makeid(19), name: "", lastname: "", email: "", phone: "", company: "", NIP: "", pass: "", code: "", companyId: 0, businessUserId: 0, acceptRegulations: false, regulations: [], paymentStatus: "IN_PROGRESS", checkbox1: false, checkbox2: false, checkbox3: false, popup: false, successPopup: false, smserror: null, preloader: false }
    this._bind('handleInputChange', 'onEnterPressed', 'validateForm', 'sendForm', 'goTo', 'sendCode', 'validate', 'getRegulations', 'addCard', 'getStatus', 'resendCode', 'sendCodeNew')

    Parsley.addMessages('pl', {
      defaultMessage: "Wartość wygląda na nieprawidłową",
      type: {
        email:        "Wpisz poprawny adres e-mail.",
        url:          "Wpisz poprawny adres URL.",
        number:       "Wpisz poprawną liczbę.",
        integer:      "Dozwolone są jedynie liczby całkowite.",
        digits:       "Dozwolone są jedynie cyfry.",
        alphanum:     "Dozwolone są jedynie znaki alfanumeryczne."
      },
      notblank:       "Pole nie może być puste.",
      required:       "Pole jest wymagane.",
      pattern:        "Pole zawiera nieprawidłową wartość.",
      min:            "Wartość nie może być mniejsza od %s.",
      max:            "Wartość nie może być większa od %s.",
      range:          "Wartość powinna zaweriać się pomiędzy %s a %s.",
      minlength:      "Minimalna ilość znaków wynosi %s.",
      maxlength:      "Maksymalna ilość znaków wynosi %s.",
      length:         "Ilość znaków wynosi od %s do %s.",
      mincheck:       "Wybierz minimalnie %s opcji.",
      maxcheck:       "Wybierz maksymalnie %s opcji.",
      check:          "Wybierz od %s do %s opcji.",
      equalto:        "Wartości nie są identyczne."
    });

    if (this.props.lang === "pl") {
      Parsley.setLocale('pl');
    }
  }

  componentDidMount() {
    document.addEventListener("keyup", this.onEnterPressed)
    setTimeout(() => { this.setState({ anim: true }) }, 600)

    if (this.props.confirm) {
      setTimeout(() => {
        this.setState({ successPopup: true, companyId: this.props.id, email: sessionStorage.getItem("userEmail") || "" })
        this.getStatus()
      }, 1100)
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onEnterPressed)
    clearTimeout(this.timer)
  }

  onEnterPressed(event) {
    event.preventDefault()
    if (event.keyCode === 13) {
      document.getElementById("button").click()
    }
  }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  handleInputChange(event) {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    let name = target.name

    if (event.target.pattern) {
      let pattern = new RegExp(event.target.pattern)
      value = pattern.test(event.target.value) ? event.target.value : this.state[name] || "";
    }

    if( event.target.className === "input-error" ) event.target.className = ""

    this.setState({
      [name]: value
    })
  }

  validateForm(e) {
    e.preventDefault()

    if($('.form').parsley().validate()) {
      this.sendForm()
    }
  }

  sendForm() {
    this.setState({ preloader: true })
    let data  = {
      name: this.state.company,
      nip: this.state.NIP,
      firstname: this.state.name,
      lastname: this.state.lastname,
      phone: this.state.phone,
      email: this.state.email,
      password: this.state.pass,
      marketingAgreement: this.state.checkbox2,
      partnerMarketingAgreement: this.state.checkbox3,
      dataProcessingAgreement: true,
      language: this.props.lang.toUpperCase()
    }

    fetch(this._apiBase + "register/dg", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'x-api-key': '46B9D48A125733B4C9226CE570007'
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
      return response.json()
    })
    .then((result) => {      
      if (result.answer === "OK") {
        //window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : ""}}), true)
        this.result = result.result
        if (result.result.smsSendError !== null) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.smsSendError}}), true)
        }
        window.gtag('event', 'b2b_rejestruje_1osdzialalnosc_wysylaformularz')
        this.setState({ preloader: false, popup: true, businessUserId: result.result.businessUserId, smserror: result.result.smsSendError })
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }   
        this.setState({ preloader: false })     
      } else if (result.answer === "IN_PROGRESS") {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => { this.sendForm() }, 3000)
      } else {
        this.setState({ preloader: false })
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError}}), true)        
      }
      if (result.fieldData && result.fieldData.fields.length > 0) {
        this.validate(result.fieldData.fields)
      }
    }, (error) => {
      this.setState({ preloader: false })
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError }}), true)      
    })
  }

  sendCode(e) {
    e.preventDefault()

    if($('.code-form').parsley().validate()) {
      let data  = {  
        phone: this.state.phone,
        email: this.state.email,
        code: this.state.code,
        businessUserId: this.state.businessUserId || null,
        business: true
      }

      fetch(this._apiBase + "register/dg/verify", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Accept-Charset': 'utf-8',
          'Accept-Language': this.props.lang,
          'x-api-key': '46B9D48A125733B4C9226CE570007'
        },
        body: JSON.stringify(data)
      })
      .then((response) => {
        return response.json()
      })
      .then((result) => {      
        if (result.answer === "OK") {
          //window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : ""}}), true)
          this.result = result.result
          this.setState({ popup: false, companyId: result.result.id })
          window.gtag('event', 'b2b_rejestruje_1osdzialalnosc_sprawdzakod')
          this.setState({ regulationsPopup: true })
        } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var i = 0; i < result.result.errors.length; i++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
          }       
          window.gtag('event', 'b2b_rejestruje_1osdzialalnosc_sprawdzakod_error') 
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError}}), true)        
          window.gtag('event', 'b2b_rejestruje_1osdzialalnosc_sprawdzakod_error')
        }
        if (result.fieldData && result.fieldData.fields.length > 0) {
          this.validate(result.fieldData.fields)
        }
      }, (error) => {
        window.gtag('event', 'b2b_rejestruje_1osdzialalnosc_sprawdzakod_error')
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError }}), true)      
      })
    }
  }

  resendCode(e) {
    e.preventDefault()

    if($('.code-form').parsley().validate()) {
      let data  = {  
        phone: this.state.phone,
        email: this.state.email,
        nip: this.state.NIP,
        userId: this.state.businessUserId || null,
        business: true,
        language: this.props.lang.toUpperCase()
      }

      fetch(this._apiBase + "register/dg/resend", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Accept-Charset': 'utf-8',
          'Accept-Language': this.props.lang,
          'x-api-key': '46B9D48A125733B4C9226CE570007'
        },
        body: JSON.stringify(data)
      })
      .then((response) => {
        return response.json()
      })
      .then((result) => {      
        if (result.answer === "OK") {
          //window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : ""}}), true)
          this.result = result.result
          window.gtag('event', 'b2b_rejestruje_1osdzialalnosc_wysylaponowniekod')
          if (result.result.smsSendError !== null) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.smsSendError}}), true)
          }
          this.setState({ popup: true, businessUserId: result.result.businessUserId, smserror: result.result.smsSendError })
        } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var i = 0; i < result.result.errors.length; i++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
          }        
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError}}), true)        
        }
        if (result.fieldData && result.fieldData.fields.length > 0) {
          this.validate(result.fieldData.fields)
        }
      }, (error) => {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError }}), true)      
      })
    }
  }

  sendCodeNew(e) {
    e.preventDefault()

    if($('.code-form').parsley().validate()) {
      let data  = {  
        phone: this.state.phone,
        email: this.state.email,
        nip: this.state.NIP,
        userId: this.state.businessUserId || null,
        business: true,
        language: this.props.lang.toUpperCase()
      }

      fetch(this._apiBase + "register/dg/change", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Accept-Charset': 'utf-8',
          'Accept-Language': this.props.lang,
          'x-api-key': '46B9D48A125733B4C9226CE570007'
        },
        body: JSON.stringify(data)
      })
      .then((response) => {
        return response.json()
      })
      .then((result) => {      
        if (result.answer === "OK") {
          //window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : ""}}), true)
          this.result = result.result
          window.gtag('event', 'b2b_rejestruje_1osdzialalnosc_nowynumer_kod')
          if (result.result.smsSendError !== null) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.smsSendError}}), true)
          }
          this.setState({ popup: true, businessUserId: result.result.businessUserId, smserror: result.result.smsSendError })
        } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var i = 0; i < result.result.errors.length; i++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
          }        
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError}}), true)        
        }
        if (result.fieldData && result.fieldData.fields.length > 0) {
          this.validate(result.fieldData.fields)
        }
      }, (error) => {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError }}), true)      
      })
    }
  }

  goTo() {
    window.gtag('event', 'b2b_rejestruje_1osdzialalnosc_dodalkarteizarejestrowalsie_success')
    if (this.props.app && (sessionStorage.getItem("pid")[0] === "I" || sessionStorage.getItem("pid")[0] === "i")) {
      window.webkit.messageHandlers.showLoginMessageHandler.postMessage({ username: this.state.email, type: "business" });
    } else if (this.props.app) {
      let data = {
        email: this.state.email,
        business: true,
        hms: sessionStorage.getItem("hms") && sessionStorage.getItem("hms") === "true" ? true : false
      }

      fetch(this._apiBase + "register/link", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Accept': 'application/json',
          'Accept-Charset': 'utf-8',
          'Accept-Language': this.props.lang,
          'x-api-key': '46B9D48A125733B4C9226CE570007'
        },
        body: JSON.stringify(data)
      })
      .then((response) => {
        return response.json()
      })
      .then((result) => {      
        if (result.answer === "OK") {
           window.location.href = result.result.value
        } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
          for (var i = 0; i < result.result.errors.length; i++) {
            window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
          }        
        } else {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError}}), true)        
        }
      }, (error) => {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError }}), true)      
      })
    } else {
      window.location.href = this.props.lang === "pl" ? "https://panelbiznesowy.itaxi.pl" : "https://panelbiznesowy.itaxi.pl/en"
    }
  }

  getRegulations() {
    fetch(this._apiBase + "register/dg/regulation", {
      method: "GET",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'x-api-key': '46B9D48A125733B4C9226CE570007'
      }
    })
    .then((response) => {
      return response.json()
    })
    .then((result) => {      
      if (result.answer === "OK") {
        this.setState({ regulationsPopup: true, regulations: result.result.regulationLinks })
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }        
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError}}), true)        
      }
      this.setState({ regulationsPopup: true })
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError }}), true)      
    })
  }

  addCard() {
    let data = {
      partnerId: this.state.companyId,
      acceptRegulations: true
    }

    fetch(this._apiBase + "register/dg/card", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'x-api-key': '46B9D48A125733B4C9226CE570007'
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
      return response.json()
    })
    .then((result) => {      
      if (result.answer === "OK") {
        sessionStorage.setItem("userEmail", this.state.email)
        window.gtag('event', 'b2b_rejestruje_1osdzialalnosc_przechodzidoBM')
        this.setState({ regulationsPopup: false, successPopup: true })
        window.location.href = result.result.url
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }        
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError}}), true)        
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError }}), true)      
    })
  }

  getStatus() {
    let data = {}

    fetch(this._apiBase + "register/dg/card/"+this.state.companyId, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
        'Accept-Charset': 'utf-8',
        'Accept-Language': this.props.lang,
        'x-api-key': '46B9D48A125733B4C9226CE570007'
      },
      body: JSON.stringify(data)
    })
    .then((response) => {
      return response.json()
    })
    .then((result) => {      
      if (result.answer === "OK") {
        this.setState({ activationDate: result.result.activationDate, paymentStatus: result.result.status })
        if(result.result.status === "IN_PROGRESS") {
          window.gtag('event', 'b2b_rejestruje_1osdzialalnosc_wtrakcieBM_odswieza_in_progress')
        } else if(result.result.status === "FAILURE") {
          window.gtag('event', 'b2b_rejestruje_1osdzialalnosc_wtrakcieBM_odswieza_error')
        } else if (result.result.status === "SUCCESS") {
          window.gtag('event', 'b2b_rejestruje_1osdzialalnosc_wtrakcieBM_odswieza_success')
        }
      } else if(result.answer === "ERROR" && result.result.errors.length > 0) {
        for (var i = 0; i < result.result.errors.length; i++) {
          window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : result.result.errors[i].msg}}), true)
        }        
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError}}), true)        
      }
    }, (error) => {
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : this.props.data.start.genericError }}), true)      
    })
  }

  validate(fields) {
    document.querySelectorAll("input.input-error").forEach((item) => {
      item.className = ""
    })
    for (var i = 0; i < fields.length; i++) {
      let field = fields[i]
      let sfield = field.split(".")
      if (sfield.length > 1) {
        field = sfield[1]
      } else {
        field = sfield[0]
      }
      if(document.getElementById(field)) document.getElementById(field).className = "input-error"
      if(field === "personName") { if(document.getElementById("businessUserId")) document.getElementById("businessUserId").className = "input-error" }
    }

    if (fields.length > 0) {
      let first = document.querySelectorAll("input.input-error")
      if(first[0]) first[0].focus()
    }
  }

  render() {
    const page = this.props.data.start.b2bData

    let modal = (
      <div className="password-modal">
        { this.state.smserror !== null ? <div className="inputs">
          <h2>{ this.props.data.start.b2cData.itTitle }</h2>
          <div className="input">
            <h4>{ this.state.smserror }</h4>
          </div>
          <div className="buttons">
            <button className="btn yellow" onClick={ this.resendCode }><span>{ page.sendAgain }</span></button>
          </div>
          <form className="code-form">
            <div className="input">
              <label htmlFor="name">{ page.newPhone }</label>
              <input type="text" pattern="^[0-9+-]*$" name="phone" id="phone" value={ this.state.phone } onChange={ this.handleInputChange } required/>
            </div>
          </form>
          <div className="buttons">
            <button className="btn yellow" id="button" onClick={ this.sendCodeNew }><span>{ page.newPhoneSend }</span></button>
          </div>
        </div> : <div className="inputs">
          <h2>{ this.props.data.start.b2cData.itTitle }</h2>
          <div className="input">
            <h4>{ page.phoneVerification }</h4>
          </div>
          <form className="code-form">
            <div className="input">
              <label htmlFor="name">{ this.props.data.start.b2cData.codeVerification }</label>
              <input type="text" name="code" id="code" value={ this.state.code } onChange={ this.handleInputChange } required/>
            </div>
          </form>
        </div> }
        { this.state.smserror === null ? <div className="buttons">
          <button className="btn yellow" id="button" onClick={ this.sendCode }><span>{ page.next }</span></button>
        </div> : null }
      </div>
    )

    let successModal = (
      <div className="password-modal">
        <div className="inputs">
          <h2>{ page.cardTitle }</h2>
          <div className="input">
            { this.state.activationDate ? <h4>{ page.activationDate + this.parseDate(this.state.activationDate, true, true) }</h4> : null }
            <h4 dangerouslySetInnerHTML={{ __html: page.status + page.paymentStatuses[this.state.paymentStatus] }}></h4>
            { this.state.paymentStatus === "SUCCESS" ? <h4 dangerouslySetInnerHTML={{ __html: page.successDescription2 }}></h4> : null }
            { this.state.paymentStatus === "FAILURE" ? <h4 dangerouslySetInnerHTML={{ __html: page.errorDescription }}></h4> : null }
          </div>
        </div>
        { this.state.paymentStatus === "SUCCESS" ? <div className="buttons">
          <button className="btn yellow" id="button" onClick={ () => { this.goTo() } }><span>{ page.finish }</span></button>
        </div> : this.state.paymentStatus === "FAILURE" ? <div className="buttons">
          <button className="btn yellow" id="button" onClick={ () => { this.setState({ regulationsPopup: true }); window.gtag('event', 'b2b_rejestruje_1osdzialalnosc_niedodalkarty_probujeponownie') } }><span>{ page.tryAgain }</span></button>
        </div> : <div className="buttons">
          <button className="btn yellow" id="button" onClick={ () => { this.getStatus() } }><span>{ page.refresh }</span></button>
        </div> }
      </div>
    )

    let regulationsModal = (
      <div className="password-modal">
        <div className="inputs">
          <h2>{ page.regulations }</h2>
          <div className="input">
            <h4 dangerouslySetInnerHTML={{ __html: page.regulationsDescription }}></h4>
          </div>
          <div className="input">
            <ul>
              { /* this.state.regulations.map((item, i) => {
                return (
                  <li key={ i }>
                    <a href={ item } target="_blank">{ page.regulations }</a>
                  </li>
                )
              }) */ }
              <li>
                <a href={ this.props.lang === "en" ? "https://itaxi.pl/terms/" : "https://itaxi.pl/regulamin/" } target="_blank">{ page.regulations }</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="buttons">
          <button className="btn yellow" id="button" onClick={ this.addCard }><span>{ page.regulationsBtn }</span></button>
        </div>
      </div>
    )

    return (
      <main>
        <section className="start b2b">
          <div className={ this.state.anim ? "start-box show" : "start-box" }>
            <NavLink to="/b2b" className="close"></NavLink>
            <form className="form">
              <div className="inputs">              
                <h4>{ page.registerSingleCompany }</h4>
                <div className="input">
                  <label htmlFor="name"><span>* </span>{ page.name }</label>
                  <input type="text" name="name" id="name" className={ this.state.nameError ? "input-error" : "" } value={ this.state.name } onChange={ this.handleInputChange } required/>
                  { this.state.nameError ? <p className="error">{ "name error" }</p> : null }
                </div>
                <div className="input">
                  <label htmlFor="lastname"><span>* </span>{ page.lastname }</label>
                  <input type="text" name="lastname" id="lastname" className={ this.state.lastnameError ? "input-error" : "" } value={ this.state.lastname } onChange={ this.handleInputChange } required/>
                  { this.state.lastnameError ? <p className="error">{ "lastname error" }</p> : null }
                </div>
                <div className="input">
                  <label htmlFor="company"><span>* </span>{ page.companyName }</label>
                  <input type="text" name="company" id="company" className={ this.state.companyError ? "input-error" : "" } value={ this.state.company } onChange={ this.handleInputChange } required/>
                  { this.state.companyError ? <p className="error">{ "company error" }</p> : null }
                </div>
                <div className="input">
                  <label htmlFor="NIP"><span>* </span>{ page.NIP }</label>
                  <input type="text" name="NIP" id="nip" className={ this.state.NIPError ? "input-error" : "" } value={ this.state.NIP } onChange={ this.handleInputChange } required/>
                  { this.state.NIPError ? <p className="error">{ "NIP error" }</p> : null }
                </div>
                <div className="input">
                  <label htmlFor="email"><span>* </span>{ page.email }</label>
                  <input type="email" name="email" id="email" className={ this.state.emailError ? "input-error" : "" } value={ this.state.email } onChange={ this.handleInputChange } required/>
                  { this.state.emailError ? <p className="error">{ "email error" }</p> : null }
                </div>
                <div className="input">
                  <label htmlFor="phone"><span>* </span>{ page.phone2 }</label>
                  <input type="text" pattern="^[0-9+-]*$" name="phone" id="phone" className={ this.state.phoneError ? "input-error" : "" } value={ this.state.phone } onChange={ this.handleInputChange } required/>
                  { this.state.phoneError ? <p className="error">{ "phone error" }</p> : null }
                </div>
                <div className="input">
                  <label htmlFor="pass"><span>* </span>{ page.pass }</label>
                  <input type="password" name="pass" id="pass" className={ this.state.passError ? "input-error" : "" } value={ this.state.pass } onChange={ this.handleInputChange } required/>
                  { this.state.passError ? <p className="error">{ "pass error" }</p> : null }
                </div>
                <div className="checkboxes">
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: page.rodoDisclaimer }}></p>
                  </div>
                  <div className="input checkbox">
                    <input type="checkbox" name="checkbox1" id="all" onChange={ () => { if (this.state.checkbox1) { this.setState({ checkbox1: false, checkbox2: false, checkbox3: false, checkbox4: false }) } else { this.setState({ checkbox1: true, checkbox2: true, checkbox3: true, checkbox4: true }) } } } checked={ this.state.checkbox1 ? true : false }/>
                    <label htmlFor="all">{ page.all }</label>
                    { this.state.checkbox1Error && !this.state.checkbox1 ? <p className="checkbox-error">{ page.checkboxRequired }</p> : null }
                  </div>
                  <div className="input checkbox">
                    <input type="checkbox" name="checkbox2" id="checkbox2" onChange={ () => { if (this.state.checkbox2) { this.setState({ checkbox1: false, checkbox2: false }) } else { this.setState({ checkbox2: true }) } } } checked={ this.state.checkbox2 ? true : false }/>
                    <label htmlFor="checkbox2">{ page.checkbox1 }</label>
                    { this.state.checkbox2Error && !this.state.checkbox2 ? <p className="checkbox-error">{ page.checkboxRequired }</p> : null }
                  </div>
                  <div className="input checkbox">
                    <input type="checkbox" name="checkbox3" id="checkbox3" onChange={ () => { if (this.state.checkbox3) { this.setState({ checkbox1: false, checkbox3: false }) } else { this.setState({ checkbox3: true }) } } } checked={ this.state.checkbox3 ? true : false }/>
                    <label htmlFor="checkbox3">{ page.checkbox2 }</label>
                    { this.state.checkbox3Error && !this.state.checkbox3 ? <p className="checkbox-error">{ page.checkboxRequired }</p> : null }
                  </div>
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: page.iod }}></p>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: page.accept }} className="terms"></p>
                </div>
                <div className="btn-holder">
                  <button className="btn yellow" id="button" onClick={ this.validateForm }><span>{ page.registerBtn2 }</span></button>
                </div>
                <div className="refresh" style={{ position: "absolute", right: "10px", bottom: "10px" }}>{ this.state.preloader ? <span className="preloader-mini"></span> : null }</div>
              </div>
            </form>
          </div>
        </section>
        <Footer data={ this.props.data }/>
        <Modal close="hide" open={ this.state.popup } hidePopup={ () => { this.setState({ popup: false }) } }>{ modal }</Modal>
        <Modal close="hide" open={ this.state.successPopup } hidePopup={ () => { this.setState({ successPopup: false }) } }>{ successModal }</Modal>
        <Modal close="hide" open={ this.state.regulationsPopup } hidePopup={ () => { this.setState({ regulationsPopup: false }) } }>{ regulationsModal }</Modal>
      </main>
    )
  }
}
